<template>
  <div class="subHeader base noFlex">
    <h2>결제 상세내역</h2>
    <section>
      <table style="margin-top: 2rem">
        <tr>
          <th scope="row" class="done">결제상태</th>
          <td>
            <p
              class="msg"
              :class="{
                green: status == 'ready',
                blue: status == 'paid',
                orange: status == 'cancelled',
                red: status == 'failed'
              }"
            >
              {{
                status == "ready"
                  ? "미결제"
                  : status == "paid"
                  ? "결제완료"
                  : status == "cancelled"
                  ? "결제취소"
                  : status == "failed"
                  ? "결제실패"
                  : ""
              }}
            </p>
          </td>
        </tr>
        <tr>
          <th scope="row" class="done">제품등록 번호</th>
          <td>
            <p class="msg">{{ merchant_uid }}</p>
          </td>
        </tr>
        <tr>
          <th scope="row" class="done">사이즈</th>
          <td>
            <tr>
              <th scope="row" class="done">기본사이즈</th>
              <td>
                <p class="msg">{{ sizeInfo[0].size }}</p>
              </td>
              <th scope="row" class="done">어깨</th>
              <td>
                <p class="msg">{{ sizeInfo[0].shoulder }}</p>
              </td>
              <th scope="row" class="done">가슴</th>
              <td>
                <p class="msg">{{ sizeInfo[0].chest }}</p>
              </td>
              <th scope="row" class="done">목</th>
              <td>
                <p class="msg">{{ sizeInfo[0].neck }}</p>
              </td>
              <th scope="row" class="done">허리</th>
              <td>
                <p class="msg">{{ sizeInfo[0].waist }}</p>
              </td>
              <th scope="row" class="done">기장</th>
              <td>
                <p class="msg">{{ sizeInfo[0].length }}</p>
              </td>
            </tr>
          </td>
        </tr>
        <tr>
          <th scope="row" class="done">스타일</th>
          <td>
            <p class="msg">{{ sizeInfo[0].styleType }}</p>
          </td>
        </tr>

        <tr>
          <th scope="row" class="done">주문명</th>
          <td>
            <p class="msg">{{ name }}</p>
          </td>
        </tr>

        <tr>
          <th scope="row" class="done">결제날짜</th>
          <td>
            <p class="msg">{{ moment(paid_at).format("YYYY년 MM월 DD일") }}</p>
          </td>
        </tr>
        <tr>
          <th scope="row" class="done">날짜</th>
          <td>
            <p class="msg">
              {{ moment(started_at).format("YYYY년 MM월 DD일") }}
            </p>
          </td>
        </tr>
        <tr>
          <th scope="row" class="done">결제방법</th>
          <td>
            <p class="msg">
              {{
                pay_method === "card"
                  ? "카드"
                  : pay_method === "vbank"
                  ? "가상계좌"
                  : pay_method === "trans"
                  ? "무통장입금"
                  : pay_method
              }}
            </p>
          </td>
        </tr>
        <tr>
          <th scope="row" class="done">결제금액</th>
          <td>
            <p class="msg">{{ comma(amount) }} 원</p>
          </td>
        </tr>
        <tr>
          <th scope="row" class="done">구매자 이름</th>
          <td>
            <p class="msg">{{ buyer_name }}</p>
          </td>
        </tr>
        <tr>
          <th scope="row" class="done">구매자 전화번호</th>
          <td>
            <p class="msg">{{ buyer_tel }}</p>
          </td>
        </tr>
        <tr>
          <th scope="row" class="done">구매자 이메일</th>
          <td>
            <p class="msg">{{ buyer_email }}</p>
          </td>
        </tr>
        <tr>
          <th scope="row" class="done">구매자 요청사항</th>
          <td>
            <p class="msg">{{ custom_data }}</p>
          </td>
        </tr>
        <tr>
          <th scope="row" class="done">구매자 주소</th>
          <td>
            <p class="msg">[{{ buyer_postcode }}] {{ buyer_addr }}</p>
          </td>
        </tr>
        <tr>
          <th scope="row" class="done">채널</th>
          <td>
            <p class="msg">{{ channel }}</p>
          </td>
        </tr>
        <tr>
          <th scope="row" class="done">화폐종류</th>
          <td>
            <p class="msg">{{ currency }}</p>
          </td>
        </tr>
        <tr>
          <th scope="row" class="done">카드명</th>
          <td>
            <p class="msg">{{ card_name }}</p>
          </td>
        </tr>
      </table>

      <router-link to="/admin/payments" class="btn">목록</router-link>
    </section>
  </div>
</template>
<script>
import { fetchPaymentDetail } from "@/api/index";
import { format } from "@/mixins/format";
import moment from "moment";
export default {
  mixins: [format],
  data() {
    return {
      moment: moment,
      showSnackbar: false,
      visible: false,
      amount: "",
      buyer_addr: "",
      buyer_postcode: "",
      buyer_email: "",
      buyer_name: "",
      buyer_tel: "",
      card_name: "",
      channel: "",
      currency: "",
      imp_uid: "",
      merchant_uid: "",
      name: "", //주문 결제명
      pay_method: "",
      paid_at: "",
      started_at: "",
      status: "",
      custom_data: "",
      vbank_code: "",
      vbank_date: "",
      vbank_name: "",
      receipt_url: "",
      sizeInfo: []
    };
  },
  mounted() {
    if (this.$route.query.imp_uid) {
      this.getPaymentInfo();
    } else if (this.$route.query.id) {
      this.getPaymentInfoByVbank();
    }
    // window.document.title = this.productName;
  },
  methods: {
    getBirthdayMask(val) {
      let res = this.getBirthday(val);
      this.residentRegistrationNumber = res;
    },
    inNumber() {
      if (
        (event.keyCode >= 48 && event.keyCode <= 57) ||
        (event.keyCode >= 96 && event.keyCode <= 105) ||
        event.keyCode == 8 ||
        event.keyCode == 37 ||
        event.keyCode == 39 ||
        event.keyCode == 46
      ) {
        return;
      } else {
        return false;
      }
    },
    removeChar() {
      if (
        event.keyCode == 8 ||
        event.keyCode == 46 ||
        event.keyCode == 37 ||
        event.keyCode == 39
      )
        return;
      else event.target.value = event.target.value.replace(/[^0-9]/g, "");
    },
    handleMove(id) {
      this.$router.push({
        path: "/quotation/register",
        query: {
          productId: id,
          url: this.thumbnail,
          fabric: this.fabric,
          productName: this.productName,
          title: this.title,
          season: this.season
        }
      });
    },
    handleInitial() {
      this.initial = "";
    },
    getPaymentInfo() {
      let params = {
        imp_uid: this.$route.query.imp_uid
      };

      fetchPaymentDetail(params).then(res => {
        if (res.status == 200) {
          this.amount = res.data.data.response.amount;
          this.buyer_addr = res.data.data.response.buyer_addr;
          this.buyer_email = res.data.data.response.buyer_email;
          this.buyer_name = res.data.data.response.buyer_name;
          this.buyer_email = res.data.data.response.buyer_email;
          this.buyer_postcode = res.data.data.response.buyer_postcode;
          this.buyer_tel = res.data.data.response.buyer_tel;
          this.card_name = res.data.data.response.card_name;
          this.channel = res.data.data.response.channel;
          this.currency = res.data.data.response.currency;
          this.custom_data = res.data.data.response.custom_data;
          this.imp_uid = res.data.data.response.imp_uid;
          this.merchant_uid = res.data.data.response.merchant_uid;
          this.name = res.data.data.response.name; //주문 결제명
          this.pay_method = res.data.data.response.pay_method;
          this.paid_at = res.data.data.response.paid_at;
          this.started_at = res.data.data.response.started_at;
          this.status = res.data.data.response.status;
          this.vbank_code = res.data.data.response.vbank_code;
          this.vbank_date = res.data.data.response.vbank_date;
          this.vbank_name = res.data.data.response.vbank_name;
          this.receipt_url = res.data.data.response.receipt_url;
          this.sizeInfo = res.data.productInfo;
        } else {
          return alert(res.data.message);
        }
      });
    }
  }
};
</script>
